<template>
  <DocumentBase :config="documentConfig" />
</template>

<script>
import DocumentBase from './components/DocumentBase.vue';

export default {
  name: 'DocumentLegal',
  components: {
    DocumentBase
  },
  data() {
    return {
      documentConfig: {
        title: 'Bonos / documentos generales',
        subtitle: 'Bonos / documentos generales',
        documentNameLabel: 'Nombre del documento',
        documentNamePlaceholder: 'Ingrese el nombre del documento',
        documentType: 'juridica',
        document: 'custom',
        area: 'relaciones-laborales',
        redirectRoute: 'recordManager',
        switches: [
          {
            model: 'is_multifirmas',
            label: 'Documento multifirmas'
          },
          {
            model: 'is_external',
            label: 'Documento externo'
          },
          {
            model: 'send_pbot',
            label: 'Enviar documento PBOT'
          },
          {
            model: 'send_duva',
            label: 'Enviar copia por correo electrónico'
          },
          {
            model: 'coordinator_signature',
            label: 'Agregar firma de Gerente de Relaciones Laborales'
          },
          {
            model: 'check_dotacion',
            label: 'Habilitar la activación del bono solo cuando el colaborador actualice la encuesta.'
          }
        ]
      }
    };
  }
};
</script>

<style>
.document-managmet___title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.document-managmet___subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #353535;
}

.document-managmet___txt-dark {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #353535;
}

.document-managmet___txt {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: #626262;
}

.document-manager__button:hover {
  background-color: #324ca1 !important;
}

.variable-carousel {
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
  min-height: 70px;
  display: flex;
  align-items: center;
}

.v-chip {
  cursor: pointer;
  transition: all 0.3s ease;
}

.v-chip:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.capitalize-text {
  text-transform: capitalize !important;
}

.v-list-item__title.capitalize-text {
  text-transform: capitalize !important;
}

.v-chip.v-size--small {
  text-transform: capitalize !important;
}

::v-deep .v-select__selections .v-chip {
  text-transform: capitalize !important;
}
</style>
